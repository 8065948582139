import React from "react"

import Layout from "../components/layout"
import {connect} from "react-redux"
import {setSteps} from "../state/app"
import {navigate} from "gatsby"

class End extends React.Component {

  render() {
    return (<Layout>

      <div  style={{ display : 'block', minHeight:"100vh",position : 'relative'}}>
        <h2 style={{marginTop:30,marginBottom :10,  }}> 
          Récapitulatif
        </h2>

        <img style={{width:'70px', transform : ' rotate(-15deg)' , top : 0, right : '0', position : 'absolute'}} src={require("../images/approved.png")} />
        
        <h4 style={{marginBottom :'10'}}>
         Ton Pitch
        </h4>
        <p  style={{marginBottom :10, marginLeft : 10, width : "100%"}}>
          {this.props.pitch}
        </p>

        <hr/>

        <h3 style={{marginLeft : 0}}>
          Les Etapes
        </h3> 
        
        <strong style={{textTransform:"uppercase", fontWeight:500, marginBottom:20}}>Distance totale</strong> {this.props.distance} km
          
        {
          this.props.steps.map((step, key) => {
            return (
            <div style={{
                marginBottom: 10,
                backgroundColor: 'white',
                width:"95%",
                padding: '10px'}}>
              <h5>{"#"+key+" - "+step.title}</h5>

              {
                (step.place && <p>
                  Nom du lieu : {step.place}
                </p>)
              }

              {
                step.comment &&
                <p>
                  Notes : {step.comment}
                </p>
              }
            </div>)
          })
        }

        <center>
          <small>
            Vous pouvez l'imprimer ou copier/coller le contenu dans un document si vous voulez le garder comme base pour créer votre jeu dans <a href='https://studio.atlantide.io'>Atlantide Studio</a>
          </small>
        </center>

        <div style={{marginTop: 10, textAlign: "center"}}>
          <button onClick={() => this.end()} className="btn hidden">
            Exporter
          </button>
        </div>
      </div>

    </Layout>)
  }
}

let activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"

export default connect(state => {
  return {pitch: state.app.pitch, steps: state.app.steps, distance : state.app.distance}
}, null,)(End)
